// store/modules/orderChat/index.js
import state from './state';
import * as mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export const orderChat = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};