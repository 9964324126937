// users/actions.js

// Импорт функции обработчика сообщений
import { handleMessages } from './handlers';

// Экспорт действия для обработки сообщений WebSocket
export const handleChatMessages = ({ commit,rootGetters,state }, message) => {
  handleMessages(commit,rootGetters , message,state);
};

export const joinOrderRoom = ({ commit, dispatch }, orderId) => {
    commit('setCurrentOrderId', orderId);
    commit('setLoadChatState', true);
    dispatch('websocket/sendMessage', {
      scope_type: 'order',
      action: 'join_order',
      order_id: orderId
    }, { root: true }); // Указываем, что действие находится в корневом уровне
    commit('setIsConnectedRoom', true); // Обновляем флаг подключения к комнате
};

export const leaveOrderRoom = ({ commit, dispatch }) => {
    dispatch('websocket/sendMessage', {
      scope_type: 'order',
      action: 'leave_order'
    }, { root: true }); // Действие находится в корневом уровне
    commit('setCurrentOrderId', null); // Сбрасываем текущий ID заказа
    commit('setAllMessages', false);
    commit('setIsConnectedRoom', false);
    commit('setMessages', []);
    commit('setMessagesProd', []);
    commit('setCounterUploaded', 0);
    commit('setCounterAll', 0)
    commit('setCounterUploadedProd', 0);
    commit('setCounterAllProd', 0);
    commit('setLoadChatState', false);
};
export const uploadMessage = ({ dispatch,commit }, {prod,lastMessageId, isAll}) => {
    commit('setLoadChatState', true);
    dispatch('websocket/sendMessage', {
        scope_type: 'chat',
        action: 'upload_chat',
        prod: prod,
        isAll: isAll,
        last_message_id: lastMessageId
    }, { root: true });

};
export const sendChatMessage = ({ dispatch },data) => {
    dispatch('websocket/sendMessage', {
        scope_type: 'chat',
        action: "send_message",
        message: data
      }, { root: true }); // Действие находится в корневом уровне
};