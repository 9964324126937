import axios from "axios";
import store from "@/store";
import router from "@/router";
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default () => {
  const baseURL = `${window.location.protocol}//${window.location.host}`;

  const instance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
  });

  // Перехватчик запросов для установки CSRF-токена перед отправкой запроса
  instance.interceptors.request.use(config => {
    config.headers['X-CSRFToken'] = getCookie('csrftoken');
    return config;
  });
  // Добавление перехватчика ответов
  instance.interceptors.response.use(response => response, error => {
    // Проверяем, связана ли ошибка с аутентификацией
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Обновляем состояние Vuex
      store.commit('auth/setAuth', false);
      store.commit('auth/setUser', null);
      // Перенаправляем пользователя на страницу входа
      router.push({ path: '/login' });
    }
    return Promise.reject(error);
  });
  return instance;
};



