// composables/useEventsType.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useEventsType() {
  const store = useStore();

  // Получаем список статусов заказа из хранилища
  const eventsTypeList = computed(() => store.getters['eventsType/eventsTypeList']);
  const uploaded = computed(() => store.getters['eventsType/uploaded']);
  const error = computed(() => store.getters['eventsType/error']);
  const eventsTypeSet = computed(() => {
    return eventsTypeList.value.reduce((acc, eventsType) => {
      acc[eventsType.id] = eventsType.name;
      return acc;
    }, {});
  });
  const fetchEventsTypeList = async () => {
    await store.dispatch('eventsType/fetchEventsTypeList');
  };
  const saveEventType = async (type) => {
    await store.dispatch('eventsType/saveEventType', type);
  };

  return {
    eventsTypeList,
    eventsTypeSet,
    saveEventType,
    isUploadedEventsTypeList :uploaded,
    error,
    fetchEventsTypeList,
  };
}