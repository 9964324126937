// store/modules/websocket/index.js
import state from './state';
import * as mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export const websocket = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};