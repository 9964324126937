//websocket/actions.js

import { getWebSocketUrl } from './helpers';
import ReconnectingWebSocket from "reconnecting-websocket";

export const initializeWebSocket = ({ commit, state,dispatch  }) => {
  if (!state.wsConnection) {
    const wsBaseUrl = getWebSocketUrl();
    const options = {
        reconnectInterval: 1000,
        maxReconnectInterval: 30000,
        reconnectDecay: 1.5,
        maxReconnectAttempts: null,
        automaticOpen: true
    };
    const newWsConnection = new ReconnectingWebSocket(wsBaseUrl, [], options);
    newWsConnection.onopen = () => {
        console.log("WebSocket connection opened");
        commit('setIsConnected', true);
        // Дополнительные действия при открытии соединения
    };
    newWsConnection.onmessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.scope_type) {
          case 'users':
            dispatch('users/handleUsersMessages', data, { root: true });
            break;
          case 'chat':
            dispatch('orderChat/handleChatMessages', data, { root: true });
            break;
          // Добавьте дополнительные case для других модулей
        }

    };
    newWsConnection.onerror = (error) => {
        console.error("WebSocket error occurred:", error);
    };
    newWsConnection.onclose = (event) => {
        console.log(event);
        console.log('WebSocket connection closed');
        commit('setIsConnected', false);
        // Дополнительные действия при закрытии соединения
    };
    commit('setWsConnection', newWsConnection);
  }
};

export const sendMessage = ({ state },message) => {
    if (state.wsConnection && state.wsConnection.readyState === WebSocket.OPEN) {
      state.wsConnection.send(JSON.stringify(message));
    } else {
      console.error("WebSocket connection is not open. Failed to send message:", message);
    }
}
