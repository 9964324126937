import { createRouter, createWebHistory } from 'vue-router';
import BasePage from "@/components/Main/BasePage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: BasePage,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/',
          name: 'Dashboard',
          meta: {
            title: 'Главная'
          },
          component: () => import('@/components/Dashboard/DashboardView.vue')
        },
        {
          name: 'Order',
          path: '/order/:orderId(\\d+)',
          meta: {
            title: 'Заявка'
          },
          component: () => import('@/components/OrderPage/OrderPageView.vue'),
          props: (route) => {
            return {
              orderId: route.params.orderId,
              activeIndex: route.query.activeIndex ? Number(route.query.activeIndex) : 1
            };
          },
        },
        {
          name: 'OrdersList',
          path: '/orders',
          meta: {
            title: 'Заявки'
          },
          component: () => import('@/components/OrderPage/OrdersListPageView.vue')
        },
        {
          name: 'Company',
          path: '/company/:company_id(\\d+)',
          meta: {
            title: 'Компания'
          },
          component: () => import('@/components/CompanyPage/CompanyPageView.vue'),
          props: true
        },
        {
          name: 'CompaniesList',
          path: '/companies/',
          meta: {
            title: 'Компании'
          },
          component: () => import('@/components/CompanyPage/CompaniesPageView.vue'),
        },
        {
          name: 'ContractsList',
          path: '/contracts/',
          meta: {
            title: 'Контракты'
          },
          component: () => import('@/components/Contract/ContractsListPageView.vue'),
        },
        {
          name: 'TasksList',
          path: '/tasks/',
          meta: {
            title: 'Задачи'
          },
          component: () => import('@/components/Task/TasksListPageView.vue'),
        },
        {
          name: 'PersonsList',
          path: '/persons/',
          meta: {
            title: 'Сотрудники'
          },
          component: () => import('@/components/Person/PersonsList.vue'),
        },
        {
          name: 'PayersList',
          path: '/payers/',
          meta: {
            title: 'Контрагенты'
          },
          component: () => import('@/components/Payer/PayersList.vue'),
        },
        {
          name: 'Statistic',
          path: '/statistic/',
          meta: {
            title: 'Статистика'
          },
          component: () => import('@/components/Statistic/StatisticPageView.vue'),
        },
        {
          name: 'Calendar',
          path: '/calendar/',
          meta: {
            title: 'Календарь'
          },
          component: () => import('@/components/Calendar/CalendarPageView.vue'),
        },
        {
          name: 'Products',
          path: '/products/',
          meta: {
            title: 'Товары'
          },
          component: () => import('@/components/Products/ProductsPageView.vue'),
        },
        {
          name: 'Settings',
          path: '/settings/',
          meta: {
            title: 'Настройки'
          },
          component: () => import('@/components/Settings/SettingsPageView.vue'),
        },
        {
          name: 'Mail',
          path: '/email/',
          meta: {
            title: 'Почта'
          },
          component: () => import('@/components/Mail/MailPageView.vue'),
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
            title: 'Вход'
          },
      component: () => import('@/components/Main/LoginPage.vue')
    }
  ],
});
import {checkAuth} from "@/utils/auth";
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const isAuthenticated = await checkAuth();
      if (isAuthenticated) {
        next(); // Пользователь аутентифицирован, разрешаем переход
      } else {
        next({
          path: '/login',
          query: { nextUrl: to.fullPath } // Используем query вместо params для передачи URL
        }); // Пользователь не аутентифицирован, перенаправляем на страницу входа
      }
    } catch (error) {
      console.error('Ошибка проверки аутентификации:', error);
      next({
        path: '/login',
        query: { nextUrl: to.fullPath }
      });
    }
  } else {
    next(); // Не требуется аутентификация, разрешаем переход
  }
});



export default router;