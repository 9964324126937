// store/modules/allUsers.js
import personAPI from "@/service/API/personAPI";

const state = {
  personsList: [],
  uploaded: false,
  error: null,
};

const getters = {
  personsList: state => state.personsList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchPersonsList({ commit }) {

    try {
      console.log('fetchPersonsList')
      const response = await personAPI.getPersonsList();
      commit('setPersonsList', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка статусов заказа:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
};

const mutations = {
  setPersonsList(state, personsList) {
    state.personsList = personsList;
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};