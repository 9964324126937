// store/modules/auth.js

import { checkAuth, login, logout } from "@/service/API/authService";
import personAPI from "@/service/API/personAPI";

const state = {
  isAuthenticated: false,
  user: null,
  authTime: null,
};
const sessionLifetime = 60*60*1000; // 1 час
const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  user(state) {
    return state.user;
  },
};

const actions = {
  async login({ commit, dispatch }, userCredentials) {
    try {
      await login(userCredentials);
      commit('setAuth', true);
      commit('setUser', null); // Сброс данных пользователя при входе
      await dispatch('fetchUser'); // Получаем данные пользователя после успешного входа
    } catch (error) {
      commit('setAuth', false);
      throw error;
    }
  },
  async logout({ commit }) {
    try {
      await logout();
      commit('setAuth', false);
      commit('setUser', null); // Сброс данных пользователя при выходе
    } catch (error) {
      console.error(error);
      commit('setAuth', false);
      throw error;
    }
  },
  async checkAuth({ state, commit, dispatch }) {
  // Проверяем, известно ли уже состояние аутентификации

    if (state.isAuthenticated) {
      // Если пользователь уже аутентифицирован, проверяем не истекло ли время сессии
      const currentTime = new Date().getTime();
      console.log('currentTime:',currentTime)
      console.log('state.authTime:',state.authTime)
      console.log('sessionLifetime:',sessionLifetime)
      console.log('if state.authTime:',currentTime - state.authTime > sessionLifetime)
      if (currentTime - state.authTime < sessionLifetime){
        commit('setTime', currentTime);
        // Если время сессии не истекло, пропускаем запрос к серверу
        return;
      }


    }

    try {
      const response = await checkAuth();
      commit('setAuth', response.data.isAuthenticated);
      if (response.data.isAuthenticated) {
        const currentTime = new Date().getTime();
        commit('setTime', currentTime);
        await dispatch('fetchUser'); // Обновляем данные пользователя, если пользователь аутентифицирован
      } else {
        commit('setTime', null); // Сброс времени, если не аутентифицирован
        commit('setUser', null); // Сброс данных пользователя, если не аутентифицирован
      }
    } catch (error) {
      commit('setAuth', false);
      commit('setUser', null);
      commit('setTime', null); // Сброс времени
      throw error;
    }
  },
  async fetchUser({ commit, state }) {
    if (state.user) { // Проверяем, есть ли уже данные пользователя в состоянии
      return; // Если есть, не делаем запрос
    }
    try {
      const response = await personAPI.getMyPerson();
      commit('setUser', response.data);
    } catch (error) {
      commit('setUser', null);
      throw error;
    }
  },
};

const mutations = {
  setAuth(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setUser(state, user) {
    state.user = user;
  },
  setTime(state, time) {
    state.authTime = time;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};