// store/modules/orderContracts.js
import orderAPI from "@/service/API/orderAPI";
import contractAPI from "@/service/API/contractAPI";

const state = {
  contracts: [],
  history: [],
  uploaded: false,
  uploadedHistory: false,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  uploaded: (state) => state.uploaded,
  uploadedHistory: (state) => state.uploadedHistory,
  contracts: (state) => state.contracts,
  history: (state) => state.history,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchContracts({ commit }, orderId) {
    try {
      const response = await orderAPI.getContracts(orderId);
      commit('setContracts', response.data); // Обновляем контракты
      commit('setUploaded', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении контрактов:', error);
      commit('setError', error);
    }
  },
  async fetchContractsHistory({ commit,state } ) {
    console.log('fetchContractsHistory')
    try {
      let arr = []
      console.log('state.contracts:',state.contracts)
      for (const item of state.contracts) {
        console.log('item.contracts:',item)
        const responseHistory = await contractAPI.getHistory(item.id);
        arr = [...arr,...responseHistory.data]
      }
      console.log('arr.contracts:',arr)
      commit('setHistory', arr);

      commit('setUploadedHistory', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении контрактов:', error);
      commit('setError', error);
    }
  },
  async updateContract({ commit, state }, data) {
    try {
      const result = await contractAPI.saveContract(data);
      const index = state.contracts.findIndex(contract => contract.id === result.data.id);
      if (index !== -1) {
        commit('updateContractAtIndex', { index, data: result.data });
        return {success:true,detail:'Контракт изменен'}
      } else {
        commit('addContract', result.data);
        return {success:true,detail:'Контракт добавлен'}
      }
    } catch (error) {
      console.error('Ошибка при обновлении контракта:', error);
      commit('setError', error);
      return {success:false,detail:error}
    }
  },async deleteContract({ commit, state }, contractId) {
    try {
      await contractAPI.deleteContract(contractId);
      const index = state.contracts.findIndex(contract => contract.id === contractId);
      if (index !== -1) {
        commit('deleteContractAtIndex', index);
        return {success:true,detail:'Контракт удален'}
      }
    } catch (error) {
      console.error('Ошибка при обновлении контракта:', error);
      commit('setError', error);
      return {success:false,detail:error}
    }
  },

  async resetOrderStore({ commit }) {
    commit('setContracts', []);
    commit('setUploaded', false);
    commit('setUploadedHistory', false);
    commit('setHistory', []);
    commit('setError', null);
    // Сброс других частей состояния по необходимости
  }
  // Дополнительные действия по необходимости
};

const mutations = {
  // Мутация для обновления контрактов
  setContracts(state, contracts) {
    state.contracts = contracts; // Устанавливаем контракты
  },
  setHistory(state, history) {
    state.history = history;
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setUploadedHistory(state, uploaded) {
    state.uploadedHistory = uploaded;
  },
  updateContractAtIndex(state, { index, data }) {
    // Замена старого контракта на новый в массиве contracts
    state.contracts.splice(index, 1, data);
  },
  deleteContractAtIndex(state,  index) {
    state.contracts.splice(index, 1);
  },
  addContract(state, contract) {
    state.contracts.push(contract);
  },
  setError(state, error) {
    state.error = error;
  },
  // Дополнительные мутации по необходимости
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};