// users/actions.js

// Импорт функции обработчика сообщений
import { handleMessages } from './handlers';

// Экспорт действия для обработки сообщений WebSocket
export const handleUsersMessages = ({ commit }, message) => {
  handleMessages(commit, message);
};

