// store/modules/orderChat/getters.js


export const messages = (state) => {
  return state.messages;
};
export const messagesProd = (state) => {
  return state.messagesProd;
};
export const loadChatState = (state) => {
  return state.loadChatState;
};
export const allMessages = (state) => {
  return state.allMessages;
};
export const counterAll = (state) => {
  return state.counterAll;
};
export const counterUploaded = (state) => {
  return state.counterUploaded;
};
export const allMessagesProd = (state) => {
  return state.allMessages;
};
export const counterAllProd = (state) => {

  return state.counterAllProd;
};
export const counterUploadedProd = (state) => {
  return state.counterUploadedProd;
};