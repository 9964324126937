export const updateAllUsers = (state, users) => {
  state.allUsers = users;
};
export const updateUserStatus = (state, { userId, userData }) => {
  state.allUsers[userId] = userData;
};




