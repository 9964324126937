
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import localRu from '@/ruLocale.json'
import { createVfm } from 'vue-final-modal'
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import Password  from 'primevue/password';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import SplitButton from 'primevue/splitbutton';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import OverlayPanel from 'primevue/overlaypanel';
import Checkbox from 'primevue/checkbox';
import Column  from 'primevue/column';
import InputText  from 'primevue/inputtext';
import InputNumber   from 'primevue/inputnumber';
import InputOtp   from 'primevue/inputotp';
import Paginator   from 'primevue/paginator';
import Inplace   from 'primevue/inplace';
import InputMask   from 'primevue/inputmask';
import InputSwitch  from 'primevue/inputswitch';
import Splitter  from 'primevue/splitter';
import SplitterPanel   from 'primevue/splitterpanel';
import TieredMenu  from 'primevue/tieredmenu';
import TriStateCheckbox  from 'primevue/tristatecheckbox';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import SelectButton from 'primevue/selectbutton';
import MultiSelect from 'primevue/multiselect';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ContextMenu from 'primevue/contextmenu';
import Menubar  from 'primevue/menubar';
import Sidebar  from 'primevue/sidebar';
import Avatar  from 'primevue/avatar';
import Image  from 'primevue/image';
import Badge  from 'primevue/badge';
import BadgeDirective  from 'primevue/badgedirective';
import Steps  from 'primevue/steps';
import Skeleton  from 'primevue/skeleton';
import FloatLabel    from 'primevue/floatlabel';
import FileUpload   from 'primevue/fileupload';
import ProgressBar   from 'primevue/progressbar';
import ProgressSpinner   from 'primevue/progressspinner';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TabMenu from 'primevue/tabmenu';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Tag  from 'primevue/tag';
import Tree  from 'primevue/tree';
import Card  from 'primevue/card';
import Timeline from 'primevue/timeline';
import TabView  from 'primevue/tabview';
import TabPanel  from 'primevue/tabpanel';
import ToastService from 'primevue/toastservice';
import Toast from "primevue/toast";
import Chart from "primevue/chart";
import Tooltip from "primevue/tooltip";
import Ripple from "primevue/ripple";
import OrganizationChart from "primevue/organizationchart";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import ToggleButton from "primevue/togglebutton";
import VirtualScroller from "primevue/virtualscroller";
import FullCalendar from '@fullcalendar/vue3';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import Lara from "@/presets/lara";

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import "@/style.css";
import "primeicons/primeicons.css";
// import 'fullcalendar/main.css'; // Стили FullCalendar


import clickOutside from "@/directives/clickOutside";


library.add(fas,far)

const vfm = createVfm()
createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue, {
        locale: localRu,
        pt: Lara
    })
    .use(ToastService)
    .use(DialogService)
    .use(vfm)
    .use(autoAnimatePlugin)
    .use(ConfirmationService)

    .component('FullCalendar', FullCalendar)
    .component('VirtualScroller', VirtualScroller)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('font-awesome-layers', FontAwesomeLayers)
    .component('font-awesome-layers-text', FontAwesomeLayersText)
    .component('DataTable', DataTable)
    .component('Column', Column )
    .component('ContextMenu', ContextMenu )
    .component('InputText', InputText )
    .component('Inplace', Inplace )
    .component('InputNumber', InputNumber )
    .component('InputOtp', InputOtp )
    .component('InputMask', InputMask )
    .component('ConfirmPopup', ConfirmPopup )
    .component('ConfirmDialog', ConfirmDialog )
    .component('Paginator', Paginator )
    .component('InputSwitch', InputSwitch )
    .component('TieredMenu', TieredMenu )
    .component('TriStateCheckbox', TriStateCheckbox )
    .component('Timeline', Timeline )
    .component('Checkbox', Checkbox )
    .component('OverlayPanel', OverlayPanel )
    .component('Calendar', Calendar)
    .component('Password', Password )
    .component('ProgressSpinner', ProgressSpinner )
    .component('Textarea', Textarea)
    .component('Dropdown', Dropdown)
    .component('Dialog', Dialog)
    .component('Button', Button)
    .component('ButtonGroup', ButtonGroup)
    .component('SplitButton', SplitButton)
    .component('Toast', Toast)
    .component('Tree', Tree)
    .component('Listbox', Listbox)
    .component('DynamicDialog', DynamicDialog)
    .component('SelectButton', SelectButton)
    .component('MultiSelect', MultiSelect)
    .component('Chip', Chip)
    .component('Chips', Chips)
    .component('Menubar', Menubar )
    .component('Sidebar', Sidebar )
    .component('Avatar', Avatar )
    .component('Image', Image )
    .component('Badge', Badge )
    .component('BadgeDirective', BadgeDirective )
    .component('Steps', Steps )
    .component('Skeleton', Skeleton )
    .component('FileUpload', FileUpload  )
    .component('FloatLabel', FloatLabel )
    .component('ProgressBar', ProgressBar  )
    .component('Accordion', Accordion  )
    .component('AccordionTab', AccordionTab  )
    .component('Splitter', Splitter  )
    .component('SplitterPanel', SplitterPanel   )
    .component('TabMenu', TabMenu  )
    .component('Menu', Menu  )
    .component('Message', Message  )
    .component('DataView', DataView  )
    .component('DataViewLayoutOptions', DataViewLayoutOptions  )
    .component('Tag', Tag   )
    .component('OrganizationChart', OrganizationChart   )
    .component('TabView', TabView   )
    .component('TabPanel', TabPanel   )
    .component('ToggleButton', ToggleButton   )
    .component('Chart', Chart   )
    .component('Card', Card   )
    .directive('ripple', Ripple)
    .directive('tooltip', Tooltip)
    .directive('badge', BadgeDirective)
    .directive('click-outside', clickOutside)

    .mount('#app')

