// composables/useOrderStatus.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useOrderStatus() {
  const store = useStore();

  // Получаем список статусов заказа из хранилища
  const statusList = computed(() => store.getters['orderStatus/statusList']);
  const uploaded = computed(() => store.getters['orderStatus/uploaded']);
  const error = computed(() => store.getters['orderStatus/error']);
  const statusSet = computed(() => {
    return statusList.value.reduce((acc, status) => {
      acc[status.id] = status.name;
      return acc;
    }, {});
  });
  const fetchOrderStatusList = async () => {
    await store.dispatch('orderStatus/fetchOrderStatusList');
  };

  return {
    statusList,
    statusSet,
    isUploadedStatusList :uploaded,
    error,
    fetchOrderStatusList,
  };
}