// composables/useContractStatus.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useContractStatus() {
  const store = useStore();

  // Получаем список статусов заказа из хранилища
  const statusList = computed(() => store.getters['contractStatus/statusList']);
  const uploaded = computed(() => store.getters['contractStatus/uploaded']);
  const error = computed(() => store.getters['contractStatus/error']);
  const statusSet = computed(() => {
    return statusList.value.reduce((acc, status) => {
      acc[status.id] = status.name;
      return acc;
    }, {});
  });
  const fetchContractStatusList = async () => {
    await store.dispatch('contractStatus/fetchContractStatusList');
  };

  return {
    statusList,
    statusSet,
    isUploadedStatusList :uploaded,
    error,
    fetchContractStatusList,
  };
}