// store/modules/orderStatus.js
import orderAPI from "@/service/API/orderAPI";
const correctStatus = ['Отменена', 'Принята', 'В работе', 'На оплате', 'Выполнена'];

const state = {
  statusList: [],
  uploaded: false,
  error: null,
};

const getters = {
  statusList: state => state.statusList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchOrderStatusList({ commit }) {

    try {
      console.log('getOrderStatusList')
      const response = await orderAPI.getOrderStatus();
      commit('setStatusList', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка статусов заказа:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
};

const mutations = {
  setStatusList(state, statusList) {
    // Сортируем список статусов согласно заданному порядку
    state.statusList = statusList.sort((a, b) => {
      return correctStatus.indexOf(a.name) - correctStatus.indexOf(b.name);
    });
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};