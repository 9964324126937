// store/modules/weekends.js

import weekendAPI from "@/service/API/weekendAPI";

const state = {
  weekendsList: [],
  uploaded: false,
  error: null,
};

const getters = {
  weekendsList: state => state.weekendsList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchWeekendsList({ commit }) {
    try {
      console.log('getContractStatusList')
      const response = await weekendAPI.getWeekends();
      commit('setWeekendsList', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка выходных:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
};

const mutations = {
  setWeekendsList(state, weekendsList) {
    state.weekendsList =weekendsList
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};