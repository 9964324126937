<template>
  <div class="w-full h-full" id="main-content">
    <RouterView :key="$route.params.orderId"/>
  </div>
</template>

<script setup>
</script>

<style>
</style>