import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getPersonsList(){
        console.log('getPersonsList')
        return API().get(`${url_pref}/person/`)
    },
    getPersons(id){
        return API().get(`${url_pref}/person/${id}/`)
    },
    getMyPerson(){
        console.log('getMyPerson')
        return API().get(`${url_pref}/person/my_user/`)
    },

}