import { computed } from 'vue';
import { useStore } from 'vuex';

export function useAuth() {
  const store = useStore();

  const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
  const user = computed(() => store.getters['auth/user']);

  const login = async (userCredentials) => {
    await store.dispatch('auth/login', userCredentials);
  };

  const logout = async () => {
    await store.dispatch('auth/logout');
  };

  const checkAuth = async () => {
    await store.dispatch('auth/checkAuth');
  };

  const fetchUser = async () => {
    await store.dispatch('auth/fetchUser');
  };

  return {
    isAuthenticated,
    user,
    login,
    logout,
    checkAuth,
    fetchUser,
  };
}