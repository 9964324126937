import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getCompanyList(params = {})  {
        return API().get(`${url_pref}/company/`, { params })
    },
    getPayers(companyId){
        console.log('getPayers by Order')
        return API().get(`${url_pref}/company/${companyId}/payers/`)
    },
    getOrders(companyId){
        console.log('getOrders by Order')
        return API().get(`${url_pref}/company/${companyId}/orders/`)
    },
    getContacts(companyId){
        console.log('getContacts by Order')
        return API().get(`${url_pref}/company/${companyId}/contacts/`)
    },
    getCompany(companyId){
        return API().get(`${url_pref}/company/${companyId}/`)
    },
    savePayer(data){
        if (data.id) {
            // Если у контрагента есть идентификатор, выполняем запрос на обновление (PATCH)
            return API().patch(`${url_pref}/payer/${data.id}/`, data);
        } else {
            // Если у контракта нет идентификатора, выполняем запрос на добавление (POST)
            return API().post(`${url_pref}/payer/`, data);
        }
    },
    postSearchCompany(uuids){
        return API().post(`${url_pref}/uuid/filter-by-uuids/`, {uuids:uuids})
    },
    editCompany(data,id){
        return API().patch((`${url_pref}/company/${id}/`), data)
            .then(
                function (response) {
                  return response
                }
            )
            .catch(
                function (error) {
                  console.log('Show error notification!')
                  console.log(error.response)
                  return error.response
                }
            ).finally()


    }

}