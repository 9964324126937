
const formatHeaderDate = (date) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const msgDate = new Date(date);
        const isToday = msgDate.toDateString() === today.toDateString();
        const isYesterday = msgDate.toDateString() === yesterday.toDateString();
        if (isToday) return 'Сегодня';
        if (isYesterday) return 'Вчера';
        return msgDate.toLocaleDateString('ru-RU', {
            year: msgDate.getFullYear() === today.getFullYear() ? undefined : 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };
let lastHeaderDate = null;
const createHeaderDateMessage = (displayMessages,message,userId) => {
    const messageDate = new Date(message.date_create).toLocaleDateString();
    if (messageDate !== lastHeaderDate) {
            displayMessages.push({
                id: `header-${messageDate}`,
                isDateHeader: true,
                dateCreate: formatHeaderDate(message.date_create)
            });
            lastHeaderDate = messageDate; // Обновляем дату последнего добавленного заголовка
        }
    if (!message.resultMessage) {
        message.from_me = message.creator.user.id === userId;
        message.for_me = message.recipient.some(item => item.user.id === userId);
    }
    displayMessages.push(message); // Добавляем сообщение в конец массива
}
export function addMessages(existingMessages, newMessages, userId) {
    console.log('addMessages userId:',userId)
    const displayMessages = []; // Временный массив для обработанных сообщений
    lastHeaderDate = null; // Переменная для хранения даты последнего добавленного заголовка
    newMessages.forEach((message) => {
        createHeaderDateMessage(displayMessages,message,userId)
    });
    // После добавления всех новых сообщений проверяем, совпадает ли последний добавленный заголовок с первым элементом в существующем списке
    if (existingMessages.length > 0 && existingMessages[0].isDateHeader &&
        existingMessages[0].id === `header-${lastHeaderDate}`) {
        existingMessages.shift(); // Удаляем первый элемент из существующего списка, если он совпадает с последним добавленным заголовком
    }

    return [...displayMessages, ...existingMessages];
}
export function addMessage(existingMessages, newMessage, userId) {
    const displayMessages = [];
    createHeaderDateMessage(displayMessages, newMessage,userId)
    return [ ...existingMessages,...displayMessages];
}
export function parseMessages (text) {
    const pattern = /(\d{2})\.(\d{2})\.(\d{2})\s+(.*?)(?=\d{2}\.\d{2}\.\d{2}|$)/gs;
  const matches = [...text.matchAll(pattern)];
  return matches.reverse().map(match => {
    // Преобразуем дату в формат YYYY-MM-DD
    const year = `20${match[3]}`;
    const month = match[2];
    const day = match[1];
    const messageText = match[4].trim();
    // Создаём объект Date, используя преобразованный формат
    const dateObj = `${year}-${month}-${day}`;
    return { date_create: dateObj, text: messageText, resultMessage:true,files:[],recipient:[] };
  });
}



