// store/modules/groupCompany.js
import contractAPI from "@/service/API/contractAPI";

const state = {
  gkList: [],
  uploaded: false,
  error: null,
};

const getters = {
  gkList: state => state.gkList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchGkList({ commit }) {

    try {
      console.log('fetchGkList')
      const response = await contractAPI.getListGK();
      commit('setGkList', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка статусов заказа:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
};

const mutations = {
  setGkList(state, gkList) {
    state.gkList = gkList;
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};