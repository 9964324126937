// store/modules/fields.js
import orderAPI from "@/service/API/orderAPI";
import contractAPI from "@/service/API/contractAPI";

const state = {
  orderFields:null,
  contractFields:null,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  orderFields: (state) => state.orderFields,
  contractFields: (state) => state.contractFields,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchOrderFields({ commit ,state}) {
    try {
      if (!state.orderFields){
        const responseFields = await orderAPI.getOrderFields();
        commit('setOrderFields', responseFields.data); // Обновляем fields
      }
    } catch (error) {
      console.error('Ошибка при получении orderFields:', error);
      commit('setError', error);
    }
  },
  async fetchContractFields({ commit ,state}) {
    try {
      if (!state.contractFields){
        const responseContractFields = await contractAPI.getContractFields();
        commit('setContractFields', responseContractFields.data); // Обновляем fields
      }
      commit('setUploaded', true);
    } catch (error) {
      console.error('Ошибка при получении contractFields:', error);
      commit('setError', error);
    }
  },
};

const mutations = {
  // Мутация для обновления текущего заказа
  setOrderFields(state, fields) {
    state.orderFields = fields; // Устанавливаем fields
  },
  setContractFields(state, fields) {
    state.contractFields = fields; // Устанавливаем contractFields
  },
  setError(state, error) {
    state.error = error;
  },
  // Дополнительные мутации по необходимости
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};