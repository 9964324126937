// composables/usePersons.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function usePersons() {
  const store = useStore();

  // Получаем список статусов заказа из хранилища
  const personsList = computed(() => store.getters['persons/personsList']);
  const uploaded = computed(() => store.getters['persons/uploaded']);
  const error = computed(() => store.getters['persons/error']);
  const personsSet = computed(() => {
    return personsList.value.reduce((acc, person) => {
      acc[person.id] = person;
      return acc;
    }, {});
  });
  const fetchPersonsList = async () => {
    await store.dispatch('persons/fetchPersonsList');
    console.log('personsSet',personsSet)
  };

  return {
    personsList,
    personsSet,
    isUploadedPersonsList :uploaded,
    error,
    fetchPersonsList,
  };
}