import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getOrderList(params = {})  {
        console.log('getOrderList')
        return API().get(`${url_pref}/order/`, { params })
    },
    getOrderLogList(params = {})  {
        console.log('getOrderLogList')
        return API().get(`${url_pref}/order-log/`, { params })
    },
    getOrderAggregateList(params = {})  {
        console.log('getOrderAggregateList')
        return API().get(`${url_pref}/order/aggregate/`, { params })
    },
    getOrderFields()  {
        console.log('getOrderFields')
        return API().get(`${url_pref}/order/fields/`)
    },
    getOrderStatus(){
        console.log('getOrderList')
        return API().get(`${url_pref}/order-status/`)
    },
    getHistory(orderId,params={}){
        console.log('getHistory by Order')
        return API().get(`${url_pref}/order/${orderId}/history/`, { params })
    },
    getEvents(orderId,params={}){
        console.log('getEvents by Order')
        return API().get(`${url_pref}/order/${orderId}/events/`, { params })
    },
    getContracts(orderId){
        console.log('getContracts by Order')
        return API().get(`${url_pref}/order/${orderId}/contracts/`)
    },
    getFiles(orderId,params={}){
        console.log('getFiles by Order')
        return API().get(`${url_pref}/order/${orderId}/files/`,{ params })
    },
    getMessages(orderId,params={}){
        console.log('getMessages by Order')
        return API().get(`${url_pref}/order/${orderId}/messages/`,{ params })
    },
    getCompany(orderId){
        console.log('getCompany by Order')
        return API().get(`${url_pref}/order/${orderId}/company/`)
    },
    getOrder(orderId){
        console.log('getOrder')
        return API().get(`${url_pref}/order/${orderId}/`)
    },
    toggleExecutor(orderId){
        console.log('toggleExecutor')
        return API().get(`${url_pref}/order/${orderId}/toggle-executor/`)
    },
    addOrder(data){
        return API().post(`${url_pref}/order/`,data)
    },
    editOrder(order_id,data){
        return API().patch(`${url_pref}/order/${order_id}/`,data)
    },

}