// composables/useGroupCompany.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useGroupCompany() {
  const store = useStore();

  // Получаем список статусов заказа из хранилища
  const gkList = computed(() => store.getters['groupCompany/gkList']);
  const uploaded = computed(() => store.getters['groupCompany/uploaded']);
  const error = computed(() => store.getters['groupCompany/error']);
  const gkSet = computed(() => {
    return gkList.value.reduce((acc, gk) => {
      acc[gk.id] = gk.name;
      return acc;
    }, {});
  });
  const fetchGkList = async () => {
    await store.dispatch('groupCompany/fetchGkList');
  };

  return {
    gkList,
    gkSet,
    isUploadedGkList :uploaded,
    error,
    fetchGkList,
  };
}