import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getBaseProducts(){
        return API().get(`${url_pref}/base-product/`)
    },
    saveBaseProduct(data){
        if (data.id) {
            return API().put(`${url_pref}/base-product/${data.id}/`, data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }else {
            return API().post(`${url_pref}/base-product/`,data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }
    },
}