// composables/useBaseProducts.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useBaseProducts() {
  const store = useStore();

  // Получаем список BaseProducts
  const productsList = computed(() => store.getters['baseProducts/productsList']);
  const uploaded = computed(() => store.getters['baseProducts/uploaded']);
  const error = computed(() => store.getters['baseProducts/error']);
  const productsSet = computed(() => {
    return productsList.value.reduce((acc, gk) => {
      acc[gk.id] = gk;
      return acc;
    }, {});
  });
  const fetchBaseProductsList = async () => {
    await store.dispatch('baseProducts/fetchBaseProductsList');
  };
  const productsUpdate = async (data) => {
    return await store.dispatch('baseProducts/productsUpdate',data);
  };

  return {
    productsList,
    productsSet,
    isUploadedBaseProducts :uploaded,
    error,
    productsUpdate,
    fetchBaseProductsList,
  };
}