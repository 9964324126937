import API from "@/service/API/API";
const url_pref = '/api/v1/auth';

export const login = (userCredentials) => {
  console.log('Login attempt');
  return API().post(`${url_pref}/login/`, userCredentials);
};

export const logout = () => {
  // Если у вас есть API для выхода из системы, вы можете добавить его здесь
  console.log('Logout attempt');
  return API().post(`${url_pref}/logout/`);
};

export const checkAuth = () => {
  console.log('Check auth attempt');
  return API().get(`${url_pref}/verify/`); // Убедитесь, что URL соответствует вашему роуту в Django
};