import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getWeekends(year=''){
        console.log('getWeekends')
        return API().get(`${url_pref}/weekends/?year=${year}`)
    },
    createWeekends(idMonth,data){
        console.log('getWeekends')
        return API().patch(`${url_pref}/weekends/${idMonth}/`, data)
    },

}