import {addMessage, addMessages, parseMessages} from "@/utils/orderChat";
import store from "@/store";

export function handleMessages(commit, rootGetters, data) {
    // Обработка сообщений чата
    switch (data.type) {
        case 'chat_message': {
            const userId = rootGetters['auth/user'].id;
            if (data.message.production) {
                const existingMessages = rootGetters['orderChat/messagesProd'];
                commit('setMessagesProd', addMessage(existingMessages, data.message, userId)); // Установка истории чата
            }else {
                const existingMessages = rootGetters['orderChat/messages'];
                commit('setMessages', addMessage(existingMessages, data.message, userId)); // Установка истории чата
            }
            break;
        }
        case 'chat_history': {
          const userId = rootGetters['auth/user'].id;
          const prod = data.prod;
          let existingMessages = prod ? rootGetters['orderChat/messagesProd'] : rootGetters['orderChat/messages'];
          const updateCounters = (isProd, total, length) => {
            const counterAllMutation = isProd ? 'setCounterAllProd' : 'setCounterAll';
            const counterUploadedMutation = isProd ? 'addCounterUploadedProd' : 'addCounterUploaded';
            commit(counterAllMutation, total);
            commit(counterUploadedMutation, length);
          };
          const updateMessages = (isProd, messages, allMessagesSet) => {
            const messagesMutation = isProd ? 'setMessagesProd' : 'setMessages';
            const allMessagesMutation = isProd ? 'setAllMessagesProd' : 'setAllMessages';
            commit(messagesMutation, messages);
            if (allMessagesSet) commit(allMessagesMutation, true);
          };
          if (!(data.first_page && existingMessages.length)) {
            updateCounters(prod, data.total_messages_count, data.messages.length);
            const isLastPage = data.last_page;
            const text = store.getters['order/currentOrder'].result || '';
            // Условие для добавления parseMessages только если isLastPage
            const newMessages = isLastPage && !prod
              ? [...parseMessages(text), ...data.messages]
              : data.messages;
            const updatedMessages = addMessages(existingMessages, newMessages, userId);
            updateMessages(prod, updatedMessages, isLastPage);
          }
          commit('setLoadChatState', false);
          break;
        }
        // Добавьте другие case для обработки разных типов сообщений внутри scope_type "chat"
    }
}