// store/modules/orderHistory.js
import orderAPI from "@/service/API/orderAPI";

const state = {
  orderId: null,
  history: [],
  uploaded: false,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  orderId: (state) => state.orderId,
  history: (state) => state.history,
  uploaded: (state) => state.uploaded,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchOrderHistory({ commit }, orderId) {
    try {
      const response = await orderAPI.getHistory(orderId);
      commit('setOrderId', orderId); // Обновляем текущий ID заказа
      commit('setHistory', response.data); // Обновляем текущий заказ
      commit('setError', null);
      commit('setUploaded', true);

    } catch (error) {
      console.error('Ошибка при получении истории заказа:', error);
      commit('setError', error);
    }
  },

  async resetOrderHistoryStore({ commit }) {
    commit('setOrderId', null);
    commit('setHistory', []);
    commit('setUploaded', false);
    commit('setError', null);
    // Сброс других частей состояния по необходимости
  }
  // Дополнительные действия по необходимости
};

const mutations = {
  // Мутация для обновления текущего заказа
  setOrderId(state, orderId) {
    state.orderId = orderId; // Устанавливаем текущий заказ
  },
  // Мутация для обновления истории
  setHistory(state, history) {
    state.history = history; // Устанавливаем контракты
  },

  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  }
  // Дополнительные мутации по необходимости
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};