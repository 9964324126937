// composables/useWeekends.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useWeekends() {
  const store = useStore();

  const weekendsList = computed(() => store.getters['weekends/weekendsList']);
  const uploaded = computed(() => store.getters['weekends/uploaded']);
  const error = computed(() => store.getters['weekends/error']);

  // Создаём словарь, где ключом будет "год-месяц", а значением — массив выходных дней
  const weekendsSet = computed(() => {
    return weekendsList.value.reduce((acc, item) => {
      const key = `${item.year}-${item.month}`;
      acc[key] = item.weekends;
      return acc;
    }, {});
  });

  const fetchWeekendsList = async () => {
    await store.dispatch('weekends/fetchWeekendsList');
  };

  // Добавляем функцию для проверки, является ли дата выходным днём
  const isWeekend = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // В JavaScript месяцы начинаются с 0
    const day = date.getDate();
    const key = `${year}-${month}`;
    const weekends = weekendsSet.value[key] || [];
    // Проверка на наличие даты в weekendsSet
    if (weekends.includes(day)) {
      return true;
    }
    // Если даты нет в weekendsSet, проверяем, является ли день субботой или воскресеньем
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };
  // Функция добавления рабочих дней
  const addWorkdays = async (startDate, daysToAdd) => {
    let addedDays = 0;
    let currentDate = new Date(startDate);

    while (addedDays < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующему дню
      if (!isWeekend(currentDate)) {
        // Если это не выходной и не суббота/воскресенье
        addedDays++;
      }
    }
    return currentDate;
  };
    // Новая функция для подсчета рабочих дней между двумя датами
  const countWorkdaysBetween = (startDate, endDate) => {
    let workdaysCount = 0;
    let currentDate = startDate?new Date(startDate):new Date();
    while (currentDate < endDate) {
      if (!isWeekend(currentDate)) {
        workdaysCount++;
      }
      currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующему дню
    }
    return workdaysCount;
  };

  return {
    addWorkdays,
    countWorkdaysBetween,
    isUploadedStatusList: uploaded,
    error,
    fetchWeekendsList,
  };
}