// directives/clickOutside.js
export default {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      // Проверяем, что клик был снаружи элемента и обработчик события определен
      if (!(el === event.target || el.contains(event.target))) {
        // Вызываем метод, переданный в аргументе директивы
        binding.value(event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    // Удаляем обработчик события при уничтожении элемента
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};