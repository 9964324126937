
export const setIsConnectedRoom = (state, status) => {
  state.isConnectedRoom = status;
};
export const setAllMessages = (state, status) => {
  state.allMessages = status;
};
export const addCounterUploaded = (state, quantity) => {
  state.counterUploaded += quantity;
};
export const setCounterUploaded = (state, quantity) => {
  state.counterUploaded = quantity;
};
export const setCounterAll = (state, quantity) => {
  state.counterAll = quantity;
};

export const setAllMessagesProd = (state, status) => {
  state.allMessagesProd = status;
};
export const addCounterUploadedProd = (state, quantity) => {
  state.counterUploadedProd += quantity;
};
export const setCounterUploadedProd = (state, quantity) => {
  state.counterUploadedProd = quantity;
};
export const setCounterAllProd = (state, quantity) => {
  state.counterAllProd = quantity;
};

export const setCurrentOrderId = (state, orderId) => {
  state.currentOrderId = orderId;
};

export const addMessage = (state, message ) => {
  state.messages = message
};
export const setMessages = (state, messages ) => {
  state.messages = messages
};
export const setMessagesProd = (state, messages ) => {
  state.messagesProd = messages
};
export const setLoadChatState = (state, loadState ) => {
  state.loadChatState = loadState;
};
