// composables/useUser.js
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useUser() {
  const store = useStore();

  const user = computed(() => store.getters['auth/user']);
  const userName = computed(() => user.value ? user.value.name : 'Гость');
  const userId = computed(() => user.value ? user.value.id : null);

  return { user, userName, userId };
}