export default () => ({
  isConnectedRoom: false,
  currentOrderId: null,
  loadChatState: false,
  messages: [],
  messagesProd: [],
  allMessages: false,
  allMessagesProd: false,
  counterAll: 0,
  counterAllProd: 0,
  counterUploaded: 0,
  counterUploadedProd: 0,
});