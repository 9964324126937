// store/modules/baseProducts.js
import productAPI from "@/service/API/productAPI";

const state = {
  productsList: [],
  uploaded: false,
  error: null,
};

const getters = {
  productsList: state => state.productsList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchBaseProductsList({ commit }) {

    try {
      console.log('fetchBaseProductsList')
      const response = await productAPI.getBaseProducts();
      commit('setProducts', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка статусов заказа:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
  async productsUpdate({ commit },data) {
    try {
      console.log('productsUpdate',data)
      const result = await productAPI.saveBaseProduct(data);
      console.log('productsUpdate result',result)
      const index = state.productsList.findIndex(product => product.id === result.data.id);
      if (index !== -1) {
        commit('updateProductAtIndex', { index, data: result.data });
        return {success:true,detail:'Товар изменен'}
      } else {
        commit('addProduct', result.data);
        return {success:true,detail:'Товар добавлен'}
      }
    } catch (error) {
      console.error('Ошибка при обновлении товара:', error);
      commit('setError', error);
      return {success:false,detail:error}
    }
  }
};

const mutations = {
  setProducts(state, productsList) {
    state.productsList = productsList;
  },
  updateProductAtIndex(state, { index, data }) {
    // Замена старого контракта на новый в массиве contracts
    state.productsList.splice(index, 1, data);
  },
  addProduct(state, product) {
    state.productsList.push(product);
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};